.contactBlock {
  background-color: #5b318c;
  border-radius: 50px;
  padding: 50px;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  text-align: center;
  margin-bottom: 50px;
}

.contactTitle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.contactSubtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
  outline: none;
}

.errorInput {
  border: 2px solid red;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkboxInput {
  width: 20px;
  height: 20px;
}

.checkboxLabel {
  font-size: 14px;
  word-break: break-word;
}

.errorLabel {
  color: #ff4d4d;
}

.submitButton {
  background-color: #fff;
  color: #5b318c;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #5b318c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notification {
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.notification.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.successNotification {
  background-color: #4caf50;
}

.errorNotification {
  background-color: #ff4d4d;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #ffffff;
}
