.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.sectionHeader {
  margin: 50px 0px;
  text-align: center;
}

.sectionTitle {
  color: #5b318c;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  display: inline-block;
}

.servicesIcon {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1;
  width: 50px; /* Установите ширину */
  height: 50px; /* Установите высоту */
  margin-right: 8px;
  color: #5b318c; /* Если нужно, установите цвет */
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.servicesItem {
  width: 33.33333%;
  padding: 0 35px 40px 85px;
  position: relative;
  margin-bottom: 40px;
}

.servicesItem:nth-child(4) {
  padding: 0 35px 40px 85px;
}
.servicesItem:nth-child(5) {
  padding: 0 35px 40px 85px;
}
.servicesItem:nth-child(6) {
  padding: 0 35px 40px 85px;
}

.servicesIcon {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1;
  font-size: 50px;
  margin-right: 8px;
  color: #5b318c;
}

.servicesTitle {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  text-transform: uppercase;
}

.servicesText {
  font-size: 16px;
  color: #999;
}

.hrThree {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #5b318c;
  margin: 0 0 80px 0px;
}

@media (max-width: 1023px) {
  .servicesItem {
    width: 50%;
    padding: 0 35px 40px 85px;
    position: relative;
    margin-bottom: 40px;
  }

  .hrTwo {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #5b318c;
    margin: 0 0 80px 0px;
  }

  .hrThree {
    display: none;
  }
}

@media (max-width: 425px) {
  .sectionHeader {
    margin: 30px 0px;
  }

  .servicesItem {
    width: 100%;
    padding: 0 35px 0px 85px;
    margin-bottom: 30px;
  }

  .hrOne {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #5b318c;
    margin: 0 0 40px 0px;
  }

  .hrThree {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #5b318c;
    margin: 0 0 40px 0px;
  }

  .hrTwo {
    margin: 0 0 40px 0px;
  }

  .servicesItem:nth-child(4) {
    padding: 0 35px 0px 85px;
  }
  .servicesItem:nth-child(5) {
    padding: 0 35px 0px 85px;
  }
  .servicesItem:nth-child(6) {
    padding: 0 35px 0px 85px;
  }
}
