/* Основные стили контейнера */
.verticalBody {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto; /* Центрируем контент */
  padding: 0 15px;
}

.verticalRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Пространство вокруг элементов */
  margin: 0 -15px;
}

.verticalColumn {
  flex: 1 1 45%; /* Две колонки на больших экранах */
  max-width: 45%; 
  padding: 15px;
  display: flex;
  align-items: stretch;
}

.verticalItem {
  background-color: #f6f9fc;
  color: #999;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
}

/* Стили для логотипов и SVG */
.verticalLabel {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.verticalLabel img {
  width: 350px; /* Фиксированная ширина для всех SVG */
  height: 350px; /* Фиксированная высота для всех SVG */
  object-fit: contain; /* Сохранение пропорций внутри контейнера */
  border-radius: 10px; /* Округлённые углы */
  transition: transform 0.3s; /* Плавный эффект при наведении */
}

.verticalLabel img:hover {
  transform: scale(1.05); /* Легкое увеличение при наведении */
}

/* Стили текста */
.verticalText {
  padding: 15px;
  font-size: 1rem;
  line-height: 1.5;
  flex-grow: 1;
}

.verticalText h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

/* Кнопка */
.verticalBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5b318c;
  margin: 10px 0;
  height: 50px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verticalBtn:hover {
  background-color: #43256d;
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .verticalColumn {
    flex: 1 1 100%; /* Одна колонка на планшетах */
    max-width: 100%;
  }

  .verticalBtn {
    font-size: 0.9rem;
    height: 45px;
  }

  .verticalLabel img {
    width: 250px; /* Уменьшение размеров SVG для планшетов */
    height: 250px;
  }
}

@media (max-width: 480px) {

  .verticalColumn {
    padding: 0px 15px;
  }

  .verticalText h3 {
    font-size: 1rem;
  }

  .verticalBtn {
    font-size: 0.8rem;
    height: 40px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .verticalLabel img {
    width: 200px; /* Ещё меньше для мобильных устройств */
    height: 200px;
  }
}
