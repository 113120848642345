/* Основные стили */
.about {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 70px;
}

/* Фоновое изображение без анимации по умолчанию */
.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  object-fit: cover;
  z-index: -1;
}

/* Включение анимации для экранов от 1024px и шире */
@media (min-width: 1024px) {
  .bgImage {
    animation: swingBackground 15s ease-in-out infinite;
  }

  @keyframes swingBackground {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-10%);
    }
    50% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(0);
    }
  }
}

/* Контейнер для логотипа и контента */
.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
}

.aboutInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.aboutContent {
  flex: 1;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.aboutHeader {
  margin-top: 0;
  font-size: 36px;
  font-weight: bold;
  color: #5b318c;
  padding: 20px 0;
}

.aboutInfo {
  color: #666;
  font-size: 18px;
  line-height: 1.6;
}

.aboutInfo p {
  margin-bottom: 20px;
}

/* Изображение команды */
.aboutImage {
  max-width: 100%;
  height: auto;
}

/* Адаптивные стили для экрана до 1024px */
@media (max-width: 1024px) {
  .aboutInner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .aboutContent {
    padding: 0;
  }

  .aboutHeader {
    margin-top: 10px;
  }
}

/* Скрытие изображения команды на экранах до 768px */
@media (max-width: 768px) {
  .aboutImage {
    display: none;
  }

  .aboutHeader {
    font-size: 28px;
  }

  .aboutInfo {
    font-size: 16px;
  }
}

/* Адаптивные стили для экранов до 425px */
@media (max-width: 425px) {
  .about {
    padding-top: 50px;
  }

  .aboutHeader {
    font-size: 24px;
    margin-top: 20px;
  }

  .aboutInfo {
    font-size: 14px;
  }
}

/* Глобальный box-sizing для корректной верстки */
* {
  box-sizing: border-box;
}
