.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 4, 15, 0.8);
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(720deg);
  }
  100% {
    transform: rotateX(1080deg);
  }
}

.preloader-logo {
  animation: rotate 4s linear infinite;
}
