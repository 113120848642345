/* Основные стили для Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #f6f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #5b318c;
  z-index: 10000;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  cursor: pointer;
}

/* Десктопное меню */
.navDesktop {
  display: flex;
  gap: 40px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.navLink {
  color: #898989;
  transition: opacity 0.3s ease, color 0.3s ease;
}

.navLink:hover {
  opacity: 0.75;
  color: #5b318c;
  cursor: pointer;
}

/* Бургер-меню */
.burger {
  width: 30px;
  height: 24px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 1001;
  position: relative;
}

.burger span {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #2c2f2e;
  border-radius: 2px;
  transition: all 0.3s ease;
}

/* Анимация для активного бургера */
.burger.open span:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
}

.burger.open span:nth-child(2) {
  opacity: 0;
}

.burger.open span:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

/* Мобильное выезжающее меню */
.overlay {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Затемнение фона */
  transition: left 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.overlayOpen {
  left: 0;
}

.navMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.navMobile .navLink {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navMobile .navLink:hover {
  background-color: rgba(91, 49, 140, 0.5); /* Фон при наведении */
  color: #fff;
  border-radius: 5px;
}

/* Адаптивные стили */
@media (max-width: 1024px) {
  .navDesktop {
    display: none;
  }

  .burger {
    display: flex;
  }
}

@media (max-width: 768px) {
  .navMobile .navLink {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .navMobile .navLink {
    font-size: 18px;
    padding: 10px 0;
  }
}
