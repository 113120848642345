.model {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  overflow: hidden;
  padding: 0px 20px;
}

.model.active {
  opacity: 1;
  pointer-events: all;
}

.modelContent {
  border-radius: 20px;

  width: 800px;
  height: 70%;
  transform: scale(0.5);
  transition: 0.4s all;
  overflow-y: scroll;

  border: 35px solid rgba(255, 255, 255, 0.8);;
  position: relative;
}

.modelContent.active {
  transform: scale(1);
}

.modelContent::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.modelContent {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modelClose {
  position: sticky;
  top: 0%;
  left: 100%;
  z-index: 1000;
  color: #000;
  font-size: 40px;
  cursor: pointer;
}

.modelClose:active {
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 1024px) {
  .model {
    padding-top: 50px;
  }
}

@media (max-width: 425px) {
  .model {
    padding-top: 110px;
  }
}

@media (max-width: 375px) {
  .modelClose {
    font-size: 30px;
  }
}
