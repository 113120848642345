.services {
  background-color: #5b318c;
  border-radius: 50px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.servicesHeader {
  text-align: center;
  padding: 50px 0px;
}

.servicesTitle {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  display: inline-block;
}

@media (max-width: 425px) {

  .services {
    border-radius: 30px;
  }

  .servicesHeader {
    padding: 30px 0px;
  }

  .servicesTitle {
    font-size: 20px;

  }
}
