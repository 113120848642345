.footer {
  background-color: #5b318c;
  border-radius: 50px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footerInner {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.footerLogo {
  flex: 1;
  display: flex;
  justify-content: center;
}

.documents {
  flex: 1;
  text-align: center;
}

.privacyPolicy {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  margin: 0 10px;
}

.privacyPolicy:hover {
  text-decoration: underline;
}

.footerLogos {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.iconPayments {
  width: 28px;
  height: 28px;
  fill: #fff;
}

.iconPayments img {
  display: block;
  max-width: 100%;
  height: auto;
}

.copyright {
  background-color: #5b318c;
  text-align: center;
}

.copyrightText {
  padding: 15px 0;
  font-size: 14px;
  color: #fff;
}

/* Медиазапросы для адаптивности */
@media (max-width: 768px) {
  .footerInner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footerLogo, .documents, .footerLogos {
    flex: none;
  }

  .footerLogo img {
    width: 80px;
    height: 50px;
  }

  .privacyPolicy {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .footer {
    border-radius: 30px;
  }

  .footerLogo img {
    width: 70px;
    height: 40px;
  }

  .iconPayments {
    width: 24px;
    height: 24px;
  }

  .copyrightText {
    font-size: 12px;
  }
}
