/* Основной блок */
.map {
  background: #5b318c;
  border-radius: 50px;
  margin: 50px 0;
  padding: 50px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.mapTitle {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
}

/* Блоки контактной информации */
.contactInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #fff;
}

.contactItem a {
  color: #fff;
  text-decoration: none;
}

.contactItem a:hover {
  text-decoration: underline;
}

.icon {
  font-size: 25px;
  color: #fff;
}

.label {
  font-weight: bold;
}

.value {
  font-weight: normal;
}

/* Карта */
.mapWrapper {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 20px;
}

.mapBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.mapAddress {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.mapAddress:hover {
  text-decoration: underline;
}

/* Адаптивные стили */
@media (max-width: 1024px) {
  .mapWrapper {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .contactItem {
    font-size: 16px;
  }

  .mapTitle {
    font-size: 22px;
  }

  .mapAddress {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .map {
    padding: 30px;
    border-radius: 30px;
  }

  .mapTitle {
    font-size: 20px;
  }

  .contactItem {
    font-size: 14px;
  }

  .icon {
    font-size: 20px;
  }
}
